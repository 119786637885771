export const AllText = {
  NONE404: {
    eng: "nullText",
    ua: "nullText",
    rus: "nullText",
    fr: "nullText",
    de: "nullText",
  },
  MAIN_HEADER: {
    eng: "TIME",
    ua: "TIME",
    rus: "TIME",
    fr: "TEMPS",
    de: "ZEIT",
  },
  LOGIN: {
    eng: "Login",
    ua: "Логін",
    rus: "Вход",
    fr: "Connexion",
    de: "Anmelden",
  },
  REGISTER: {
    eng: "Register",
    ua: "Реєстрація",
    rus: "Регистрация",
    fr: "S'inscrire",
    de: "Registrieren",
  },
  EMAIL: {
    eng: "Email",
    ua: "Email",
    rus: "Email",
    fr: "Email",
    de: "Email",
  },
  PASSWORD: {
    eng: "Password",
    ua: "Пароль",
    rus: "Пароль",
    fr: "Mot de passe",
    de: "Passwort",
  },
  FULL_NAME: {
    eng: "Full Name",
    ua: "Повне ім'я",
    rus: "Полное имя",
    fr: "Nom complet",
    de: "Vollständiger Name",
  },
  CONFIRM_PASSWORD: {
    eng: "Confirm Password",
    ua: "Підтвердити пароль",
    rus: "Подтвердите пароль",
    fr: "Confirmer le mot de passe",
    de: "Passwort bestätigen",
  },
  CHANGE_LANGUAGE: {
    eng: "Change language",
    ua: "Змінити мову",
    rus: "Изменить язык",
    fr: "Changer de langue",
    de: "Sprache ändern",
  },
  EVENTS: {
    eng: "Events",
    ua: "Події",
    rus: "События",
    fr: "Événements",
    de: "Veranstaltungen",
  },
  KEYS: {
    eng: "Keys",
    ua: "Ключі",
    rus: "Ключи",
    fr: "Clés",
    de: "Schlüssel",
  },
  MENU: {
    eng: "Menu",
    ua: "Меню",
    rus: "Меню",
    fr: "Menu",
    de: "Menü",
  },
  MESSAGE: {
    eng: "Message",
    ua: "Повідомлення",
    rus: "Сообщение",
    fr: "Message",
    de: "Nachricht",
  },

  EVENT_DATE: {
    eng: "Event date",
    ua: "Дата події",
    rus: "Дата события",
    fr: "Date de l'événement",
    de: "Veranstaltungsdatum",
  },
  NAME: {
    eng: "Name",
    ua: "Ім'я",
    rus: "Имя",
    fr: "Nom",
    de: "Name",
  },
  STATUS: {
    eng: "Status",
    ua: "Статус",
    rus: "Статус",
    fr: "Statut",
    de: "Status",
  },
  CANCEL: {
    eng: "Cancel",
    ua: "Скасувати",
    rus: "Отмена",
    fr: "Annuler",
    de: "Abbrechen",
  },
  OK: {
    eng: "Ok",
    ua: "Ok",
    rus: "Ok",
    fr: "Ok",
    de: "Ok",
  },
  SAVE: {
    eng: "Save",
    ua: "Зберегти",
    rus: "Сохранить",
    fr: "Enregistrer",
    de: "Speichern",
  },
  DELETE: {
    eng: "Delete",
    ua: "Видалити",
    rus: "Удалить",
    fr: "Supprimer",
    de: "Löschen",
  },
  LOGOUT: {
    eng: "Logout",
    ua: "Вихід",
    rus: "Выход",
    fr: "Déconnexion",
    de: "Abmelden",
  },
  CREATE_EVENT: {
    eng: "Create event",
    ua: "Створити подію",
    rus: "Создать событие",
    fr: "Créer un événement",
    de: "Ereignis erstellen",
  },
  CREATE: {
    eng: "Create",
    ua: "Створити",
    rus: "Создать",
    fr: "Créer",
    de: "Erstellen",
  },
  CREATE_KEY: {
    eng: "Create key",
    ua: "Створити ключ",
    rus: "Создать ключ",
    fr: "Créer une clé",
    de: "Schlüssel erstellen",
  },
  EXPIRED_DATE: {
    eng: "Expired Date",
    ua: "Дата закінчення терміну дії",
    rus: "Дата окончания срока действия",
    fr: "Date d'expiration",
    de: "Ablaufdatum",
  },
  EDIT_KEY: {
    eng: "Edit key",
    ua: "Редагувати ключ",
    rus: "Редактировать ключ",
    fr: "Modifier la clé",
    de: "Schlüssel bearbeiten",
  },
  ERROR_NAME_REQ: {
    eng: "Name is required",
    ua: "Ім'я є обов'язковим",
    rus: "Имя является обязательным",
    fr: "Le nom est requis",
    de: "Name ist erforderlich",
  },
  ERROR_EVENT_DATE_REQ: {
    eng: "Event Date is required",
    ua: "Дата події є обов'язковою",
    rus: "Дата события обязательна",
    fr: "La date de l'événement est requise",
    de: "Veranstaltungsdatum ist erforderlich",
  },
  ERROR_EXPIRED_DATE_REQ: {
    eng: "Expired Date is required",
    ua: "Дата закінчення терміну дії є обов'язковою",
    rus: "Дата окончания срока действия обязательна",
    fr: "La date d'expiration est requise",
    de: "Ablaufdatum ist erforderlich",
  },
  USAGE_COUNT: {
    eng: "Usage Count",
    ua: "Лічильник використання",
    rus: "Счетчик использования",
    fr: "Nombre d'utilisations",
    de: "Nutzungsanzahl",
  },
  UNLIMITED: {
    eng: "Unlimited",
    ua: "Необмежено",
    rus: "Неограниченный",
    fr: "Illimité",
    de: "Unbegrenzt",
  },
  STOP: {
    eng: "Stop",
    ua: "Стоп",
    rus: "Стоп",
    fr: "Arrêter",
    de: "Stoppen",
  },
  STOP_EVENT: {
    eng: "Stop Event",
    ua: "Зупинити подію",
    rus: "Остановить событие",
    fr: "Arrêter l'événement",
    de: "Ereignis stoppen",
  },
  WANT_STOP_EVENT: {
    eng: "Do you really want to stop the event?",
    ua: "Ви дійсно хочете зупинити подію?",
    rus: "Вы действительно хотите остановить событие?",
    fr: "Voulez-vous vraiment arrêter l'événement?",
    de: "Möchten Sie das Ereignis wirklich stoppen?",
  },
  DELETE_EVENT: {
    eng: "Delete Event",
    ua: "Видалити подію",
    rus: "Удалить событие",
    fr: "Supprimer l'événement",
    de: "Ereignis löschen",
  },
  WANT_DELETE_EVENT: {
    eng: "Do you really want to delete the event?",
    ua: "Ви дійсно хочете видалити подію?",
    rus: "Вы действительно хотите удалить событие?",
    fr: "Voulez-vous vraiment supprimer l'événement?",
    de: "Möchten Sie das Ereignis wirklich löschen?",
  },

  DELETE_KEY: {
    eng: "Delete Key",
    ua: "Видалити ключ",
    rus: "Удалить ключ",
    fr: "Supprimer la clé",
    de: "Schlüssel löschen",
  },
  WANT_DELETE_KEY: {
    eng: "Do you really want to delete the key?",
    ua: "Ви дійсно хочете видалити ключ?",
    rus: "Вы действительно хотите удалить ключ?",
    fr: "Voulez-vous vraiment supprimer la clé?",
    de: "Möchten Sie den Schlüssel wirklich löschen?",
  },
  SHARE: {
    eng: "Share",
    ua: "Поділитися",
    rus: "Поделиться",
    fr: "Partager",
    de: "Teilen",
  },
  USE_KEY: {
    eng: "Use Key",
    ua: "Використовувати ключ",
    rus: "Использовать ключ",
    fr: "Utiliser la clé",
    de: "Schlüssel verwenden",
  },
  CREATED_DATE: {
    eng: "Created date",
    ua: "Дата створення",
    rus: "Дата создания",
    fr: "Date de création",
    de: "Erstellungsdatum",
  },
  DAY: {
    eng: "day",
    ua: "день",
    rus: "день",
    fr: "jour",
    de: "Tag",
  },
  DAYS: {
    eng: "days",
    ua: "днів",
    rus: "дней",
    fr: "jours",
    de: "Tage",
  },
  TODAY: {
    eng: "Today",
    ua: "Сьогодні",
    rus: "Сегодня",
    fr: "Aujourd'hui",
    de: "Heute",
  },
  CANCELED: {
    eng: "Canceled",
    ua: "Скасовано",
    rus: "Отменено",
    fr: "Annulé",
    de: "Abgesagt",
  },
  PENDING: {
    eng: "Pending",
    ua: "Очікується",
    rus: "Ожидается",
    fr: "En attente",
    de: "Ausstehend",
  },
  SUCCESS: {
    eng: "Success",
    ua: "Успішно",
    rus: "Успешно",
    fr: "Succès",
    de: "Erfolg",
  },
  EXPIRED: {
    eng: "Expired",
    ua: "Закінчився",
    rus: "Истек",
    fr: "Expiré",
    de: "Abgelaufen",
  },
  DELETED: {
    eng: "Deleted",
    ua: "Видалено",
    rus: "Удалено",
    fr: "Supprimé",
    de: "Gelöscht",
  },
  UNKNOWN: {
    eng: "Unknown",
    ua: "Невідомо",
    rus: "Неизвестно",
    fr: "Inconnu",
    de: "Unbekannt",
  },
  CLOSE: {
    eng: "Close",
    ua: "Закрити",
    rus: "Закрыть",
    fr: "Fermer",
    de: "Schließen",
  },
  INV_EVENT: {
    eng: "Invitation to the event",
    ua: "Запрошення на подію",
    rus: "Приглашение на событие",
    fr: "Invitation à l'événement",
    de: "Einladung zum Ereignis",
  },
  ERROR_USELINK: {
    eng: "To use the link, log in to the app or register quickly",
    ua: "Для використання лінку увійдіть в додаток або пройдіть швидку реєстрацію",
    rus: "Для использования ссылки войдите в приложение или пройдите быструю регистрацию",
    fr: "Pour utiliser le lien, connectez-vous à l'application ou inscrivez-vous rapidement",
    de: "Um den Link zu verwenden, melden Sie sich in der App an oder registrieren Sie sich schnell",
  },
  ERROR_USEKEY: {
    eng: "To use the key, log in to the app or register quickly",
    ua: "Для використання ключа увійдіть в додаток або пройдіть швидку реєстрацію",
    rus: "Для использования ключа войдите в приложение или пройдите быструю регистрацию",
    fr: "Pour utiliser la clé, connectez-vous à l'application ou inscrivez-vous rapidement",
    de: "Um den Schlüssel zu verwenden, melden Sie sich in der App an oder registrieren Sie sich schnell",
  },
  NAME_KEY: {
    eng: "Name key",
    ua: "Назва ключа",
    rus: "Название ключа",
    fr: "Nom de la clé",
    de: "Schlüsselname",
  },
  CREATE_EVENT_FOR: {
    eng: "Create event for",
    ua: "Створити подію для",
    rus: "Создать событие для",
    fr: "Créer un événement pour",
    de: "Ereignis erstellen für",
  },
  KEY_LOADING: {
    eng: "Key loading…",
    ua: "Завантаження ключа…",
    rus: "Загрузка ключа…",
    fr: "Chargement de la clé…",
    de: "Schlüssel wird geladen…",
  },
  INVITATION: {
    eng: "Invitation",
    ua: "Запрошення",
    rus: "Приглашение",
    fr: "Invitation",
    de: "Einladung",
  },
  ACCEPT_INVITATION: {
    eng: "Accept the invitation?",
    ua: "Прийняти запрошення?",
    rus: "Принять приглашение?",
    fr: "Accepter l'invitation?",
    de: "Einladung annehmen?",
  },
  ACCEPT: {
    eng: "Accept",
    ua: "Прийняти",
    rus: "Принять",
    fr: "Accepter",
    de: "Akzeptieren",
  },
  ADD_KEY_TITLE: {
    eng: "Add Key",
    ua: "Додати ключ",
    rus: "Добавить ключ",
    fr: "Ajouter une clé",
    de: "Schlüssel hinzufügen",
  },
  WANT_ADD_KEY: {
    eng: "Do you want to add a key?",
    ua: "Ви хочете додати ключ?",
    rus: "Хотите добавить ключ?",
    fr: "Voulez-vous ajouter une clé?",
    de: "Möchten Sie einen Schlüssel hinzufügen?",
  },
  ADD_KEY: {
    eng: "Add Key",
    ua: "Додати ключ",
    rus: "Добавить ключ",
    fr: "Ajouter une clé",
    de: "Schlüssel hinzufügen",
  },

  ALL_KEYS: {
    eng: "All keys",
    ua: "Усі ключі",
    rus: "Все ключи",
    fr: "Toutes les clés",
    de: "Alle Schlüssel",
  },
  MY_KEY: {
    eng: "My keys",
    ua: "Мої ключі",
    rus: "Мои ключи",
    fr: "Mes clés",
    de: "Meine Schlüssel",
  },
  FRIENDS_KEYS: {
    eng: "Friends' keys",
    ua: "Ключі друзів",
    rus: "Ключи друзей",
    fr: "Clés d'amis",
    de: "Schlüssel von Freunden",
  },

  CONFIRM_MAIL: {
    eng: "Confirm mail",
    ua: "Підтвердьте пошту",
    rus: "Подтвердите почту",
    fr: "Confirmer le mail",
    de: "E-Mail bestätigen",
  },
  CONG_EMAIL_CONFIRMED: {
    eng: "Congratulations!!! Email confirmed.",
    ua: "Вітаємо! Електронну пошту підтверджено.",
    rus: "Поздравляем! Почта подтверждена.",
    fr: "Félicitations! E-mail confirmé.",
    de: "Herzlichen Glückwunsch! E-Mail bestätigt.",
  },
  CONFIRM_LINK_ERROR: {
    eng: "Confirm Link error",
    ua: "Помилка підтвердження посилання",
    rus: "Ошибка подтверждения ссылки",
    fr: "Erreur de confirmation du lien",
    de: "Bestätigungslink-Fehler",
  },
  CONTINUE: {
    eng: "Continue",
    ua: "Продовжити",
    rus: "Продолжить",
    fr: "Continuer",
    de: "Fortsetzen",
  },

  FORGOT_PASS: {
    eng: "Forgot a password?",
    ua: "Забули пароль?",
    rus: "Забыли пароль?",
    fr: "Mot de passe oublié ?",
    de: "Passwort vergessen?",
  },
  ENTER_MAIL: {
    eng: "Enter the email which you registered your account, which you want to restore now",
    ua: "Введіть електронну пошту, на яку ви зареєстрували свій обліковий запис, який хочете відновити",
    rus: "Введите электронную почту, на которую вы зарегистрировали свой аккаунт, который хотите восстановить",
    fr: "Entrez l'e-mail avec lequel vous avez enregistré votre compte que vous souhaitez restaurer maintenant",
    de: "Geben Sie die E-Mail ein, mit der Sie Ihr Konto registriert haben, das Sie jetzt wiederherstellen möchten",
  },
  SEND_LINK: {
    eng: "Send link",
    ua: "Надіслати посилання",
    rus: "Отправить ссылку",
    fr: "Envoyer le lien",
    de: "Link senden",
  },
  RESET_PASS_TITLE: {
    eng: "Reset Password",
    ua: "Скинути пароль",
    rus: "Сбросить пароль",
    fr: "Réinitialiser le mot de passe",
    de: "Passwort zurücksetzen",
  },
  RESET_PASWORD: {
    eng: "Reset Password",
    ua: "Скинути пароль",
    rus: "Сбросить пароль",
    fr: "Réinitialiser le mot de passe",
    de: "Passwort zurücksetzen",
  },

  PARTICIPANS: {
    eng: "Participants",
    ua: "Учасники",
    rus: "Участники",
    fr: "Participants",
    de: "Teilnehmer",
  },
  ONLY_PENDING: {
    eng: "Only pending",
    ua: "Тільки очікування",
    rus: "Только ожидается",
    fr: "Seulement en attente",
    de: "Nur ausstehend",
  },

  CODE_1: {
    eng: "Invalid login or password",
    ua: "Неправильний логін або пароль",
    rus: "Неверный логин или пароль",
    fr: "Identifiant ou mot de passe invalide",
    de: "Ungültiger Login oder Passwort",
  },

  CLICK_COPY_LINK: {
    eng: "Click to copy link",
    ua: "Натисніть, щоб скопіювати посилання",
    rus: "Нажмите, чтобы скопировать ссылку",
    fr: "Cliquez pour copier le lien",
    de: "Klicken Sie, um den Link zu kopieren",
  },
  LINK_COPY: {
    eng: "Link copy",
    ua: "Посилання скопійовано",
    rus: "Ссылка скопирована",
    fr: "Lien copié",
    de: "Link kopiert",
  },

  HOT_KEY_COPY: {
    eng: "Hot key copy",
    ua: "Гарячу клавішу скопійовано",
    rus: "Горячая клавиша скопирована",
    fr: "Raccourci copié",
    de: "Tastenkombination kopiert",
  },
  CREATE_HOT_CODE: {
    eng: "Create hot code",
    ua: "Створити гарячий код",
    rus: "Создать горячий код",
    fr: "Créer un code rapide",
    de: "Schnellcode erstellen",
  },

  QR_SCANNING: {
    eng: "QR code scanning",
    ua: "Сканування QR-коду",
    rus: "Сканирование QR-кода",
    fr: "Scan du code QR",
    de: "QR-Code-Scan",
  },

  INSERT_HOT_KEY: {
    eng: "Insert hot key",
    ua: "Введіть гарячу клавішу",
    rus: "Введите горячую клавишу",
    fr: "Insérer le raccourci",
    de: "Tastenkombination eingeben",
  },
  USE_HOT_KEY: {
    eng: "Use hot key",
    ua: "Використовуйте гарячу клавішу",
    rus: "Используйте горячую клавишу",
    fr: "Utiliser le raccourci",
    de: "Tastenkombination verwenden",
  },
  ERROR_KEY_REQ: {
    eng: "Key is required",
    ua: "Ключ обов'язковий",
    rus: "Требуется ключ",
    fr: "Clé requise",
    de: "Schlüssel erforderlich",
  },

  FOLLOW_LINK: {
    eng: "Follow the link",
    ua: "Перейдіть за посиланням",
    rus: "Перейдите по ссылке",
    fr: "Suivez le lien",
    de: "Folgen Sie dem Link",
  },
  LOADING: {
    eng: "Loading…",
    ua: "Завантаження…",
    rus: "Загрузка…",
    fr: "Chargement…",
    de: "Laden…",
  },

  EMAIL_CONFIRMED: {
    eng: "Email confirmed",
    ua: "Електронна пошта підтверджена",
    rus: "Электронная почта подтверждена",
    fr: "E-mail confirmé",
    de: "E-Mail bestätigt",
  },
  ADD_EVENT_YOURSELF: {
    eng: "Add an event for yourself",
    ua: "Додайте подію для себе",
    rus: "Добавьте событие для себя",
    fr: "Ajoutez un événement pour vous-même",
    de: "Fügen Sie ein Ereignis für sich selbst hinzu",
  },

  INCORRECT_QR: {
    eng: "Incorrect QR code",
    ua: "Неправильний QR-код",
    rus: "Неправильный QR-код",
    fr: "Code QR incorrect",
    de: "Falscher QR-Code",
  },

  ORGANIZER: {
    eng: "Organizer",
    ua: "Організатор",
    rus: "Организатор",
    fr: "Organisateur",
    de: "Organisator",
  },
  CLEAR_ALL: {
    eng: "Clear All",
    ua: "Очистити все",
    rus: "Очистить все",
    fr: "Tout effacer",
    de: "Alles löschen",
  },
  NOTIFICATIONS: {
    eng: "Notifications",
    ua: "Сповіщення",
    rus: "Уведомления",
    fr: "Notifications",
    de: "Benachrichtigungen",
  },

  EVENT_ORGANIZE: {
    eng: "I am the event organizer",
    ua: "Я організатор події",
    rus: "Я организатор события",
    fr: "Je suis l'organisateur de l'événement",
    de: "Ich bin der Veranstalter",
  },

  ADD_ADDRESS: {
    eng: "Add address",
    ua: "Додати адресу",
    rus: "Добавить адрес",
    fr: "Ajouter une adresse",
    de: "Adresse hinzufügen",
  },
  ADDRESS: {
    eng: "Address",
    ua: "Адреса",
    rus: "Адрес",
    fr: "Adresse",
    de: "Adresse",
  },
  ERROR_ADDRESS: {
    eng: "The address was not found on the map but you can save the entered text",
    ua: "Адресу не знайдено на карті, але ви можете зберегти введений текст",
    rus: "Адрес не найден на карте, но вы можете сохранить введённый текст",
    fr: "L'adresse n'a pas été trouvée sur la carte mais vous pouvez enregistrer le texte saisi",
    de: "Die Adresse wurde auf der Karte nicht gefunden, aber Sie können den eingegebenen Text speichern",
  },

  ERROR_FILL_FIELDS: {
    eng: "Please fill in all fields",
    ua: "Будь ласка, заповніть усі поля",
    rus: "Пожалуйста, заполните все поля",
    fr: "Veuillez remplir tous les champs",
    de: "Bitte füllen Sie alle Felder aus",
  },
  ERROR_REGISTER_PASS: {
    eng: "The password should be between 8 and 16 characters long and contain only Latin letters, numbers, or special characters (without quotation marks)",
    ua: "Пароль має бути довжиною від 8 до 16 символів і містити лише латинські букви, цифри або спеціальні символи (без лапок)",
    rus: "Пароль должен быть длиной от 8 до 16 символов и содержать только латинские буквы, цифры или специальные символы (без кавычек)",
    fr: "Le mot de passe doit comporter entre 8 et 16 caractères et contenir uniquement des lettres latines, des chiffres ou des caractères spéciaux (sans guillemets)",
    de: "Das Passwort muss zwischen 8 und 16 Zeichen lang sein und nur lateinische Buchstaben, Zahlen oder Sonderzeichen enthalten (ohne Anführungszeichen)",
  },

  ERROR_NOTMATCH_PASS: {
    eng: "Passwords do not match",
    ua: "Паролі не збігаються",
    rus: "Пароли не совпадают",
    fr: "Les mots de passe ne correspondent pas",
    de: "Passwörter stimmen nicht überein",
  },
  SUPPORT: {
    eng: "Support",
    ua: "Підтримка",
    rus: "Поддержка",
    fr: "Support",
    de: "Unterstützung",
  },
  USE_FORM_SUPPORT: {
    eng: "Use the form to reach out to our support team with any questions or issues",
    ua: "Скористайтеся формою, щоб зв’язатися з нашою командою підтримки з будь-якими питаннями чи проблемами",
    rus: "Используйте форму, чтобы связаться с нашей командой поддержки с любыми вопросами или проблемами",
    fr: "Utilisez le formulaire pour contacter notre équipe de support pour toute question ou problème",
    de: "Verwenden Sie das Formular, um unser Support-Team mit Fragen oder Problemen zu kontaktieren",
  },
  EMAIL_SUPPORT: {
    eng: "Email",
    ua: "Електронна пошта",
    rus: "Электронная почта",
    fr: "E-mail",
    de: "E-Mail",
  },
  SUPPORT_TITLE: {
    eng: "Support",
    ua: "Підтримка",
    rus: "Поддержка",
    fr: "Support",
    de: "Unterstützung",
  },
  DESCRIBE_PROBLEM: {
    eng: "Please, describe your problem",
    ua: "Будь ласка, опишіть вашу проблему",
    rus: "Пожалуйста, опишите вашу проблему",
    fr: "Veuillez décrire votre problème",
    de: "Bitte beschreiben Sie Ihr Problem",
  },
  MESSAGE_SUPPORT: {
    eng: "Your message to support",
    ua: "Ваше повідомлення до служби підтримки",
    rus: "Ваше сообщение в службу поддержки",
    fr: "Votre message au support",
    de: "Ihre Nachricht an den Support",
  },
  SEND: {
    eng: "Send",
    ua: "Відправити",
    rus: "Отправить",
    fr: "Envoyer",
    de: "Senden",
  },
  ERROR_EMAIL_REQ: {
    eng: "Email is required",
    ua: "Електронна пошта обов'язкова",
    rus: "Электронная почта обязательна",
    fr: "E-mail requis",
    de: "E-Mail erforderlich",
  },
  ERROR_MESSAGE_REQ: {
    eng: "Message is required",
    ua: "Повідомлення обов'язкове",
    rus: "Сообщение обязательно",
    fr: "Message requis",
    de: "Nachricht erforderlich",
  },
  ADDRESS_NOT_FOUND: {
    eng: "Address not found on the map (check with the event creator)",
    ua: "Адресу не знайдено на карті (перевірте у організатора події)",
    rus: "Адрес не найден на карте (уточните у создателя события)",
    fr: "Adresse introuvable sur la carte (vérifiez auprès du créateur de l'événement)",
    de: "Adresse nicht auf der Karte gefunden (fragen Sie den Veranstalter)",
  },
  ENABLE_LIVE_QUEUE: {
    eng: "Enable Live Queue",
    ua: "Увімкнути живу чергу",
    rus: "Включить живую очередь",
    fr: "Activer la file d'attente en direct",
    de: "Live-Warteschlange aktivieren",
  },
  ONLY_LIVE_QUEUE: {
    eng: "Only Live Queue",
    ua: "Тільки жива черга",
    rus: "Только живая очередь",
    fr: "Seulement la file d'attente en direct",
    de: "Nur Live-Warteschlange",
  },
  INVITE: {
    eng: "Invite",
    ua: "Запросити",
    rus: "Пригласить",
    fr: "Inviter",
    de: "Einladen",
  },
  FINISH: {
    eng: "Finish",
    ua: "Завершити",
    rus: "Завершить",
    fr: "Terminer",
    de: "Fertigstellen",
  },
  INVITE_USER: {
    eng: "Invite user",
    ua: "Запросити користувача",
    rus: "Пригласить пользователя",
    fr: "Inviter un utilisateur",
    de: "Benutzer einladen",
  },
  END_MEETING: {
    eng: "End the meeting with the",
    ua: "Завершити зустріч з",
    rus: "Завершить встречу с",
    fr: "Terminer la réunion avec",
    de: "Das Meeting mit beenden",
  },
  YOUR_QUEUE_IN: {
    eng: "Your queue in",
    ua: "Ваша черга в",
    rus: "Ваша очередь в",
    fr: "Votre file d'attente dans",
    de: "Ihre Warteschlange in",
  },
  PERSONS: {
    eng: "persons",
    ua: "осіб",
    rus: "человек",
    fr: "personnes",
    de: "Personen",
  },
  PERSON: {
    eng: "person",
    ua: "особа",
    rus: "человек",
    fr: "personne",
    de: "Person",
  },
  YOUR_TURN: {
    eng: "Your turn",
    ua: "Ваша черга",
    rus: "Ваш ход",
    fr: "Votre tour",
    de: "Ihr Zug",
  },
  CURRENT_NUMBER: {
    eng: "Current number",
    ua: "Поточний номер",
    rus: "Текущий номер",
    fr: "Numéro actuel",
    de: "Aktuelle Nummer",
  },
  MY_NUMBER: {
    eng: "My number",
    ua: "Мій номер",
    rus: "Мой номер",
    fr: "Mon numéro",
    de: "Meine Nummer",
  },
  TO_CHANGE_PASS: {
    eng: "To change the password, you need to log out of your account and follow the password reset link again.",
    ua: "Щоб змінити пароль, потрібно вийти з акаунту і знову перейти за посиланням для скидання паролю.",
    rus: "Чтобы изменить пароль, необходимо выйти из аккаунта и снова пройти по ссылке для сброса пароля.",
    fr: "Pour changer le mot de passe, vous devez vous déconnecter de votre compte et suivre à nouveau le lien de réinitialisation du mot de passe.",
    de: "Um das Passwort zu ändern, müssen Sie sich aus Ihrem Konto abmelden und dem Link zum Zurücksetzen des Passworts erneut folgen.",
  },
  POLICY: {
    eng: "Policy",
    ua: "Політика",
    rus: "Политика",
    fr: "Politique",
    de: "Richtlinie",
  },
  MORE: {
    eng: "More...",
    ua: "Більше...",
    rus: "Подробнее...",
    fr: "Plus...",
    de: "Mehr...",
  },
  TITLE_POLICY: {
    eng: "Title policy",
    ua: "Політика заголовка",
    rus: "Политика заголовка",
    fr: "Politique du titre",
    de: "Titelrichtlinie",
  },
  TEXT_POLICY: {
    eng: "Text policy",
    ua: "Політика тексту",
    rus: "Политика текста",
    fr: "Politique de texte",
    de: "Textpolitik",
  },
  COPYRIGHT: {
    eng: "copyright © 2024",
    ua: "авторські права © 2024",
    rus: "авторские права © 2024",
    fr: "droit d'auteur © 2024",
    de: "Urheberrecht © 2024",
  },
  TUTORIAL: {
    eng: "Tutorial",
    ua: "Посібник",
    rus: "Руководство",
    fr: "Tutoriel",
    de: "Tutorial",
  },
  TUTORIAL_TITLE: {
    eng: "Tutorial",
    ua: "Посібник",
    rus: "Руководство",
    fr: "Tutoriel",
    de: "Tutorial",
  },
  DONT_SHOW_TUTORIAL: {
    eng: "Don’t show this tutorial again",
    ua: "Не показувати цей посібник знову",
    rus: "Не показывать это руководство снова",
    fr: "Ne montrez plus ce tutoriel",
    de: "Zeigen Sie dieses Tutorial nicht mehr",
  },
  ERROR_001: {
    eng: "User already exists",
    ua: "Користувач вже існує",
    rus: "Пользователь уже существует",
    fr: "L'utilisateur existe déjà",
    de: "Benutzer existiert bereits",
  },
  ERROR_002: {
    eng: "An incorrectly entered password or login",
    ua: "Неправильно введений пароль або логін",
    rus: "Некорректно введен пароль или логин",
    fr: "Mot de passe ou identifiant incorrect",
    de: "Falsch eingegebenes Passwort oder Login",
  },
};

export const parsLanguage = (text = "NONE404", lng = "eng") => {
  return AllText?.[text]?.[lng] ? AllText?.[text]?.[lng] : AllText.NONE404[lng];
};
