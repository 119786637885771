import io from "socket.io-client";

const SOCKET_PATH = "https://managerstage.onrender.com";
let socket;

const storageName = "userData";

export const initSocket = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.close();
  }

  const data = JSON.parse(localStorage.getItem(storageName));


  socket = io(SOCKET_PATH, {
    autoConnect: true,
    path: "/socket.io",
    forceNew: true,
    withCredentials: false,
    transports: ["websocket", "polling"],
    auth: {
      token: data?.token || "",
    },
    transportOptions: {
      polling: {
        extraHeaders: {
          "X-USER-TOKEN": data?.token || "",
        },
      },
    },
  });
};

export const getSocket = () => {
  if (!socket) {
    return false;
  }
  return socket;
};
