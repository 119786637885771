import { useState, useEffect, useContext } from "react";

import { ContextStore } from "context/ContextStore";
import { useHttp } from "hooks";

import { diffInDays, diffInMinutes, dateMoment } from "utils/common";

export const useMessagesPage = () => {
  const [events, setEvents] = useState([]);
  const [isOpenModalQrReader, setIsOpenModalQrReader] = useState(false);
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [isOpenModalHot, setIsOpenModalHot] = useState(false);
  const [checkedPending, setCheckedPending] = useState(() => {
    return JSON.parse(localStorage.getItem("onlyPending")) ?? false;
  });
  const [viewMode, setViewMode] = useState(() => {
    return JSON.parse(localStorage.getItem("viewMode")) ?? "table";
  });
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [hotCode, setHotCode] = useState(null);
  const [timerId, setTimerId] = useState(null);
  const [isOpenModalCalendarEvent, setIsOpenModalCalendarEvent] =
    useState(false);
  const [currentCalendarEventId, setCurrentCalendarEventId] = useState(null);
  const [checkedLiveOueue, setCheckedLiveOueue] = useState(false);
  const [isLiveToggle, setIsLiveToggle] = useState(false);

  const { request } = useHttp();
  const { token, userId, notif, pageScrollDown, socket_triger } =
    useContext(ContextStore);

  useEffect(() => {
    getDataTable();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (socket_triger?.action === "event_canceled") {
      getDataTable();
    }
    // eslint-disable-next-line
  }, [socket_triger]);

  useEffect(() => {
    eventFilter(events, checkedPending, checkedLiveOueue);
    // eslint-disable-next-line
  }, [events, checkedPending, checkedLiveOueue]);

  useEffect(() => {
    const liveToggleStatus = liveToggleShow(events);
    setIsLiveToggle(liveToggleStatus);

    if (!liveToggleStatus) {
      setCheckedLiveOueue(false);
    }
    // eslint-disable-next-line
  }, [events]);

  useEffect(() => {
    localStorage.setItem("onlyPending", JSON.stringify(checkedPending));
  }, [checkedPending]);

  useEffect(() => {
    localStorage.setItem("viewMode", JSON.stringify(viewMode));
  }, [viewMode]);

  const syntheticStatusParse = (eventDate, status) => {
    const daysDiff = diffInDays(eventDate);

    return daysDiff < 0 || (daysDiff === 0 && diffInMinutes(eventDate) <= 2)
      ? 3
      : status;
  };

  const pendingFilter = (arr) =>
    arr.filter(
      (item) => syntheticStatusParse(item.eventDate, item.status) === 2
    );

  const liveQueuePendingFilter = (arr) =>
    arr.filter(
      (item) =>
        item.isLiveQueue &&
        syntheticStatusParse(item.eventDate, item.status) === 2
    );

  const liveToggleShow = (arr) =>
    arr.some(
      (item) =>
        item.isLiveQueue &&
        syntheticStatusParse(item.eventDate, item.status) === 2
    );

  const eventFilter = (arr, valueCheckedPending, valueCheckedLiveOueue) => {
    const sortedArr = [...arr].sort((a, b) =>
      dateMoment(b.eventDate).diff(dateMoment(a.eventDate))
    );

    if (valueCheckedLiveOueue) {
      setFilteredEvents(liveQueuePendingFilter(sortedArr));
      return;
    }

    if (valueCheckedPending) {
      setFilteredEvents(pendingFilter(sortedArr));
    } else {
      setFilteredEvents(sortedArr);
    }
  };

  const closeModalCreate = () => {
    setIsOpenModalCreate(false);
  };

  const handleAddButton = () => {
    setIsOpenModalCreate(true);
  };

  const closeModalQrReader = () => {
    setIsOpenModalQrReader(false);
  };

  const handleQrButton = () => {
    setIsOpenModalQrReader(true);
  };

  const closeModalHot = () => {
    setIsOpenModalHot(false);
  };

  const handleHotButton = () => {
    setIsOpenModalHot(true);
  };

  const openModalCalendarEvent = () => {
    setIsOpenModalCalendarEvent(true);
  };

  const closeModalCalendarEvent = () => {
    setIsOpenModalCalendarEvent(false);
  };

  const onClickCalendarEvent = (id) => {
    setCurrentCalendarEventId(id);
    if (id) {
      openModalCalendarEvent();
    }
  };

  const handleSubmit = async (formData) => {
    try {
      await request(`/api/events/addEvent`, "POST", formData, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleDel = async (_id) => {
    const REQ = { id: _id };
    try {
      await request(`/api/events/delEvent`, "POST", REQ, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleStop = async (_id) => {
    const REQ = { eventId: _id, newStatus: 1 };
    try {
      await request(`/api/events/updateStatus`, "POST", REQ, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const getDataTable = async () => {
    try {
      const eventsRes = await request(`/api/events/getAll`, "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      setEvents(eventsRes);
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleSwitch = (e) => {
    setCheckedPending(e.target.checked);
  };

  const handleSwitchLiveOueue = (e) => {
    setCheckedLiveOueue(e.target.checked);
  };

  const handleModeView = (e, newValue) => {
    setViewMode(newValue);
  };

  const generateHotKey = async (_id) => {
    try {
      const { hotKey, message } = await request(
        `/api/events/generateHotKey/${_id}`,
        "PUT",
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setHotCode(hotKey);
      startTimer();
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const timeHotCode = 60;

  const startTimer = () => {
    timerId && clearTimeout(timerId);
    const timer = setTimeout(function () {
      setHotCode(null);
    }, timeHotCode * 1000);
    setTimerId(timer);
  };

  const useHotKey = async (key) => {
    const REQ = { hotKey: key };
    try {
      const message = await request(`/api/events/useHotKey`, "POST", REQ, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  return {
    filteredEvents,
    isOpenModalCreate,
    isOpenModalQrReader,
    isOpenModalHot,
    userId,
    closeModalCreate,
    handleAddButton,
    closeModalQrReader,
    handleQrButton,
    closeModalHot,
    handleHotButton,
    handleSubmit,
    handleDel,
    handleStop,
    getDataTable,
    checkedPending,
    handleSwitch,
    hotCode,
    setHotCode,
    generateHotKey,
    timeHotCode,
    timerId,
    useHotKey,
    pageScrollDown,
    handleModeView,
    viewMode,
    isOpenModalCalendarEvent,
    closeModalCalendarEvent,
    onClickCalendarEvent,
    currentCalendarEventId,
    checkedLiveOueue,
    handleSwitchLiveOueue,
    isLiveToggle,
  };
};
