import { createContext } from "react";
import { DEFAULT_LANGUAGE } from "config/configs";

function noop() {}

export const ContextStore = createContext({
  token: null,
  userId: null,
  login: noop,
  logout: noop,
  ready: false,
  isAuthenticated: false,
  lng: DEFAULT_LANGUAGE,
  changeLanguage: noop,
  notif: noop,
  pageScrollDown: noop,
  socket_triger: {},
  IsOpenModalTutorial: false,
});
