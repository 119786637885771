import React, { useRef } from "react";

import {
  Header,
  NavMenu,
  ModalResetPass,
  Modal,
  ModalTutorial,
} from "components/Elements";

import { useRoutes } from "./routes";
import { useStore, useTranslate, useApp } from "./hooks";
import { ContextStore } from "./context/ContextStore";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { theme } from "./utils/theme";

import { navConfig } from "./config/configs";

import cn from "classnames";

import "./App.css";
import classes from "App.module.scss";

function App() {
  const wrapPageRef = useRef(null);

  const {
    token,
    login,
    logout,
    userId,
    ready,
    isAuthenticated,
    lng,
    changeLanguage,
    notif,
    pageScrollDown,
    socket_triger,
    setSocket_triger,
    isOpenModalTutorial,
    openModalTutorial,
    closeModalTutorial,
  } = useStore(wrapPageRef);

  const {
    isOpenModalResetPass,
    isOpenModalLogoutResetPass,
    closeModalResetPass,
    closeModalLogoutResetPass,
    removeParamResetPass,
    resetPass,
    handleLogoutAndResetPass,
    isLoginPage,
    hideTutorial,
    handleChangeCheckTutorial,
  } = useApp(isAuthenticated, logout, notif, openModalTutorial);

  const routes = useRoutes(isAuthenticated);

  const { t } = useTranslate();

  if (!ready) {
    return <p>...loading...</p>;
  }

  return (
    <ContextStore.Provider
      value={{
        token,
        userId,
        login,
        logout,
        isAuthenticated,
        lng,
        changeLanguage,
        notif,
        pageScrollDown,
        socket_triger,
        setSocket_triger,
        openModalTutorial,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.container}>
          {isAuthenticated && (
            <Header changeLanguage={changeLanguage} lng={lng} />
          )}
          <div
            className={cn(
              classes.wrapPage,
              isLoginPage && classes.fullScreenWrap
            )}
            ref={wrapPageRef}
          >
            {routes}
          </div>
          {isAuthenticated && <NavMenu navConfig={navConfig} />}
        </div>

        {isOpenModalTutorial && (
          <ModalTutorial
            open={isOpenModalTutorial}
            closeModal={closeModalTutorial}
            title={t("TUTORIAL_TITLE")}
            checked={hideTutorial}
            onChangeChecked={handleChangeCheckTutorial}
          />
        )}

        {isOpenModalResetPass && (
          <ModalResetPass
            onSubmit={resetPass}
            open={isOpenModalResetPass}
            closeModal={closeModalResetPass}
            title={t("RESET_PASS_TITLE")}
            textButtonOk={t("RESET_PASWORD")}
            additionalFuncCancel={removeParamResetPass}
          />
        )}

        {isOpenModalLogoutResetPass && (
          <Modal
            onSubmit={handleLogoutAndResetPass}
            open={isOpenModalLogoutResetPass}
            closeModal={closeModalLogoutResetPass}
            text={t("TO_CHANGE_PASS")}
            textButtonOk={t("LOGOUT")}
          />
        )}
      </ThemeProvider>
    </ContextStore.Provider>
  );
}

export default App;
